const VehicleReducer = (state, action) => {
  if (action.type === "FIRST_RENDER") {
    return {
      ...state,
      vId: action.payload.vDetails[0].id,
      type: action.payload.vType,
      fullDetailsArray: action.payload.vDetails,
      productDetail: action.payload.vDetails[0],
      model: action.payload.vDetails[0].model,
      chasis: action.payload.vDetails[0].variant.chasis
        ? action.payload.vDetails[0].variant.chasis[0].type
        : "",
      body: action.payload.vDetails[0].variant.body
        ? action.payload.vDetails[0].variant.body[0].type
        : "",
      extColor: action.payload.vDetails[0].variant.extColor[0].type,
      intColor: action.payload.vDetails[0].variant.intColor[0].type,
      range: action.payload.vDetails[0].range,
      minRange: action.payload.vDetails[0].minRange,
      interiorSelected: false,
    };
  } else if (action.type === "SELECT_PRODUCT") {
    return {
      ...state,
      vId: action.payload.id,
      productDetail: action.payload,
      model: action.payload.model,
      range: action.payload.range,
      minRange: action.payload.minRange,
      interiorSelected: false,
    };
  } else if (action.type === "SELECT_CHASIS") {
    return {
      ...state,
      chasis: action.payload.type,
    };
  } else if (action.type === "SELECT_BODY") {
    return {
      ...state,
      body: action.payload.type,
    };
  } else if (action.type === "SELECT_EXTPAINT") {
    return {
      ...state,
      extColor: action.payload.type,
      interiorSelected: false,
    };
  } else if (action.type === "SELECT_INTPAINT") {
    return {
      ...state,
      intColor: action.payload.type,
      interiorSelected: true,
    };
  } else if (action.type === "SELECT_RANGE") {
    return {
      ...state,
      range: action.payload,
    };
  }
};

export default VehicleReducer;
