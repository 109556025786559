import React from "react";

import { Wrapper } from "./charger.style";

import { useState } from "react";
import { motion } from "framer-motion";

const ChargerOne = () => {
  document.title = "Model X | Tesla";
  const [assemblyInfo, setAssemblyInfo] = useState({
    grid: "",
    solar: "",
    solution: "",
    assembly: "",
  });
  const setInitialState = () => {
    assemblyInfo.grid = "false";
    assemblyInfo.solar = "false";
    assemblyInfo.solution = "false";
    assemblyInfo.assembly = "no";
  };

  const handleRadio = (arg) => (e) => {
    const name = e.target.name;
    if (arg === "grid") {
      setInitialState();
    }
    setAssemblyInfo({ ...assemblyInfo, [name]: e.target.value });
  };

  const clickMe = () => {
    console.log(assemblyInfo, "Info");
  };

  return (
    <Wrapper>
      <motion.div
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
        className="container"
      >
        <motion.div
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
          className="radio-option"
        >
          <label htmlFor="grid">Grid electricity</label>
          <input
            type="radio"
            name="grid"
            value="true"
            onChange={handleRadio("grid")}
            checked={assemblyInfo.grid === "true"}
          />
        </motion.div>
        <motion.div
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
          className="radio-option"
        >
          <label htmlFor="solar">Solar panel</label>
          <input
            type="radio"
            name="solar"
            value="true"
            onChange={handleRadio("grid")}
            checked={assemblyInfo.solar === "true"}
          />
        </motion.div>
        <motion.div
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
          className="radio-option"
        >
          <label htmlFor="solution">Solution required</label>
          <input
            type="radio"
            name="solution"
            value="true"
            onChange={handleRadio("grid")}
            checked={assemblyInfo.solution === "true"}
          />
        </motion.div>

        {(assemblyInfo.grid === "true" || assemblyInfo.solar === "true") && (
          <motion.div
            initial={{ x: 300 }}
            animate={{ x: 0 }}
            transition={{ duration: 1 }}
            className="radio-option"
          >
            <label htmlFor="assembly">Local Assembly required</label>
            <div className="yes-radio">
              Yes
              <input
                type="radio"
                name="assembly"
                value="yes"
                id="assembly"
                onChange={handleRadio("local")}
                checked={assemblyInfo.assembly === "yes"}
              />
            </div>
            <div className="yes-radio">
              No
              <input
                type="radio"
                name="assembly"
                value="no"
                id="assembly"
                onChange={handleRadio("local")}
                checked={assemblyInfo.assembly === "no"}
              />
            </div>
          </motion.div>
        )}
        {(assemblyInfo.assembly === "yes" ||
          assemblyInfo.solution === "true") && (
          <motion.div
            initial={{ x: -300 }}
            animate={{ x: 0 }}
            transition={{ duration: 1 }}
            className="radio-option"
          >
            <label htmlFor="planning">
              In house planning and infra support
            </label>
            <input type="radio" id="planning" />
          </motion.div>
        )}

        <div className="button-container">
          <button onClick={clickMe}>Submit</button>
        </div>
      </motion.div>
    </Wrapper>
  );
};

export default ChargerOne;
