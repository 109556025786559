import React, { useEffect } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./card.css";

import { Pagination, Navigation } from "swiper/modules";

import "../../index.css";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const CardCarousel = ({
  closeDialog,
  feature,
  fullDetails,
  productDetail,
  type,
}) => {
  const [swiperRef, setSwiperRef] = useState(null);
  useEffect(() => {
    console.log(fullDetails, "product details");
  }, [fullDetails]);

  return (
    <div>
      {type === "city-bus" && (
        <div>
          <Swiper
            className="swipe"
            onSwiper={setSwiperRef}
            slidesPerView={2}
            centeredSlides={true}
            spaceBetween={30}
            navigation={true}
            modules={[Pagination, Navigation]}
          >
            <SwiperSlide className="container">
              <div className="cross" onClick={closeDialog}>
                <FontAwesomeIcon icon={faXmark} />
              </div>
              <div className="overflow">
                <div className="grid-container">
                  <div>
                    <h5 className="title">Seating capacity</h5>
                    <div className="align-left">{productDetail.seats}</div>
                  </div>
                  <div>
                    <h5 className="title">Rated passengers capacity</h5>
                    <div className="align-left">{productDetail.passengers}</div>
                  </div>
                  <div>
                    <h5 className="title">Approach angle/departure angle</h5>
                    <div className="align-left">{productDetail.angle}</div>
                  </div>
                  <div>
                    <h5 className="title">Max Speed(km/h)</h5>
                    <div className="align-left">{productDetail.speed}</div>
                  </div>
                  <div>
                    <h5 className="title">Maximum Climbing slope(%)</h5>
                    <div className="align-left">{productDetail.slope}</div>
                  </div>
                  {productDetail.ramp && (
                    <div>
                      <h5 className="title">Ramp starting ability</h5>
                      <div className="align-left">{productDetail.ramp}</div>
                    </div>
                  )}
                  {productDetail.slopeAbility && (
                    <div>
                      <h5 className="title">Slope stability ability</h5>
                      <div className="align-left">
                        {productDetail.slopeAbility}
                      </div>
                    </div>
                  )}
                  <div>
                    <h5 className="title">Total Length/width/height(mm)</h5>
                    <div className="align-left">
                      {productDetail.dimension.slice(0, 15)} <br />
                      {productDetail.dimension.slice(15)}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="container">
              <div className="cross" onClick={closeDialog}>
                <FontAwesomeIcon icon={faXmark} />
              </div>
              <div className="overflow">
                <div className="grid-container">
                  <div>
                    <h5 className="title">Braking performance</h5>
                    <div className="align-left">{productDetail.braking}</div>
                  </div>
                  <div>
                    <h5 className="title">Driving range(km)</h5>
                    <div className="align-left">{productDetail.range}</div>
                  </div>
                  <div>
                    <h5 className="title">Power battery</h5>
                    <div className="align-left">{productDetail.battery}</div>
                  </div>
                  <div>
                    <h5 className="title">Charging form</h5>
                    <div className="align-left">{productDetail.charging}</div>
                  </div>
                  <div>
                    <h5 className="title">360 degree panoramic system</h5>
                    <div className="align-left">
                      {productDetail.panoramicSystem}
                    </div>
                  </div>
                  <div>
                    <h5 className="title">Driver fatigue monitoring system</h5>
                    <div className="align-left">
                      {productDetail.fatigueSystem}
                    </div>
                  </div>
                  {productDetail.tyre && (
                    <div>
                      <h5 className="title">Tyre</h5>
                      <div className="align-left">{productDetail.tyre}</div>
                    </div>
                  )}
                  {productDetail.rim && (
                    <div>
                      <h5 className="title">Wheel rims</h5>
                      <div className="align-left">{productDetail.rim}</div>
                    </div>
                  )}
                  {productDetail.tyreAndRim && (
                    <div>
                      <h5 className="title">Tyre and Rims</h5>
                      <div className="align-left">
                        {productDetail.tyreAndRim}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      )}
      <div>
        {type === "coach-bus" && (
          <div>
            <Swiper
              className="swipe"
              onSwiper={setSwiperRef}
              slidesPerView={2}
              centeredSlides={true}
              spaceBetween={30}
              navigation={true}
              modules={[Pagination, Navigation]}
            >
              <SwiperSlide className="container">
                <div className="cross" onClick={closeDialog}>
                  <FontAwesomeIcon icon={faXmark} />
                </div>
                <div className="overflow">
                  <div className="grid-container">
                    <div>
                      <h5 className="title">Seating capacity</h5>
                      <div className="align-left">{productDetail.seats}</div>
                    </div>
                    <div>
                      <h5 className="title">Total length/width/height(mm)</h5>
                      <div className="align-left">
                        {productDetail.dimension}
                      </div>
                    </div>
                    <div>
                      <h5 className="title">Wheelbase(mm)</h5>
                      <div className="align-left">
                        {productDetail.wheelbase}
                      </div>
                    </div>
                    <div>
                      <h5 className="title">Front Overhang(mm)</h5>
                      <div className="align-left">
                        {productDetail.frontOverhang}
                      </div>
                    </div>
                    <div>
                      <h5 className="title">Rear Overhang(mm)</h5>
                      <div className="align-left">
                        {productDetail.rearOverhang}
                      </div>
                    </div>
                    <div>
                      <h5 className="title">Track Width(mm)</h5>
                      <div className="align-left">
                        {productDetail.trackWidth}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="container">
                <div className="cross" onClick={closeDialog}>
                  <FontAwesomeIcon icon={faXmark} />
                </div>
                <div className="overflow">
                  <div className="grid-container">
                    <div>
                      <h5 className="title">Approach angle/departure angle</h5>
                      <div className="align-left">{productDetail.angle}</div>
                    </div>
                    <div>
                      <h5 className="title">Max Speed(km/h)</h5>
                      <div className="align-left">{productDetail.speed}</div>
                    </div>
                    <div>
                      <h5 className="title">Maximum Climbing slope(%)</h5>
                      <div className="align-left">{productDetail.slope}</div>
                    </div>
                    <div>
                      <h5 className="title">Slope stability ability</h5>
                      <div className="align-left">
                        {productDetail.slopeAbility}
                      </div>
                    </div>
                    <div>
                      <h5 className="title">Braking performance</h5>
                      <div className="align-left">{productDetail.braking}</div>
                    </div>
                    <div>
                      <h5 className="title">Driving range(km)</h5>
                      <div className="align-left">{productDetail.range}</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="container">
                <div className="cross" onClick={closeDialog}>
                  <FontAwesomeIcon icon={faXmark} />
                </div>
                <div className="overflow">
                  <div className="grid-container">
                    <div>
                      <h5 className="title">Power battery</h5>
                      <div className="align-left">{productDetail.battery}</div>
                    </div>
                    <div>
                      <h5 className="title">Charging port</h5>
                      <div className="align-left">{productDetail.charging}</div>
                    </div>
                    <div>
                      <h5 className="title">Maximum Climbing slope(%)</h5>
                      <div className="align-left">{productDetail.slope}</div>
                    </div>
                    <div>
                      <h5 className="title">Tyres and rims</h5>
                      <div className="align-left">
                        {productDetail.tyreAndRim}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardCarousel;
