import React from "react";

import { Wrapper } from "./school.style";

import Section from "../Section";
import Interior from "../Interior_Specs";
import Specs from "../Gen_Spec";
import Header from "../Header";
import Footer from "../Footer";
import { useMyState } from "../MyStateContext";
import { useEffect } from "react";

const SchoolBus = () => {
  document.title = "Model X | Tesla";
  const { state, dispatch } = useMyState();
  const { fullDetails } = state;

  return (
    <Wrapper>
      <Header />
      {console.log(state, "STATE")}
      <Section
        title="School Bus"
        desc="Plaid"
        backgroundImg="model-x.jpg"
        arrow="true"
        range="333"
        speed="2.5"
        top="175"
        hp="1,020"
      />
      <Interior />
      <Specs />
      <Footer bottom="bottom" />
    </Wrapper>
  );
};

export default SchoolBus;
