import React, { useEffect, useState } from "react";

export const NumberCounter = ({
  initialValue,
  targetNumber,
  duration,
  vid,
}) => {
  const [count, setCount] = useState(initialValue);

  useEffect(() => {
    let startTimestamp;
    let animationFrameId;

    function updateCount(timestamp) {
      if (!startTimestamp) {
        startTimestamp = timestamp;
      }

      const elapsed = timestamp - startTimestamp;
      const progress = Math.min(elapsed / duration, 1); // Ensure it doesn't exceed 1

      setCount(Math.round(targetNumber * progress));

      if (elapsed < duration) {
        animationFrameId = requestAnimationFrame(updateCount);
      }
    }

    animationFrameId = requestAnimationFrame(updateCount);
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [targetNumber, duration, initialValue, vid]);

  return <div>{count}</div>;
};
