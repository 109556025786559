import React from "react";
import { useMyState } from "../MyStateContext";
import { imageDetails } from "../imageDetails";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../../index.css";
import { Navigation } from "swiper/modules";
import "./imgCarousel.css";
import { useState, useEffect } from "react";

const ImageCarousel = () => {
  const { state } = useMyState();
  const { model, interiorSelected, extColor, intColor } = state;
  const [images, setImages] = useState("");
  // let obj = imageDetails.filter((el) => {
  //   if (type === "bus") {
  //     return el.lentgh === length && el.paint === paint;
  //   } else if (type === "truck") {
  //     return el.chasis === chasis;
  //   }
  // });

  useEffect(() => {
    imageDetails.find((obj) => {
      if (obj.model === model) {
        if (interiorSelected) {
          setImages(obj.interiorImage);
        } else {
          setImages(obj.exteriorImage);
        }
      }
    });
  }, [extColor, intColor, model, interiorSelected]);
  // console.log(images, "img");

  return (
    <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
      {images &&
        images.map((ele, i) => {
          return (
            <SwiperSlide key={i}>
              <img className="mySwiper-img" src={ele.url} alt="A" />
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export default ImageCarousel;
