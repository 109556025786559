import React from "react";

import { Wrapper } from "./coach.style";

import Section from "../Section";
import Interior from "../Interior_Specs";
import Specs from "../Gen_Spec";
import Header from "../Header";
import Footer from "../Footer";

const CoachBus = () => {
  document.title = "Coach Bus | BVX";
  return (
    <Wrapper>
      <Header display="flex" />
      <Section
        title="Coach Bus"
        desc="11m coach"
        backgroundImg="coachBus.png"
        arrow="true"
        range=">=200"
        speed="9"
        top="100"
        // hp="1,020"
        buildLink="/order/coach-bus"
      />
      <Interior />
      <Specs />
      <Footer bottom="bottom" />
    </Wrapper>
  );
};

export default CoachBus;
