import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// Using AOS for animation

// Components
import GlobalStyle from "./globalstyles";
import Home from "./components/home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Account from "./components/Account";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, login, logout } from "./features/userSlice";
import { auth } from "./components/firebase";

import DeliveryTruck from "./components/Delivery-Truck";
import FoodTruck from "./components/Food-Truck";
import RefuseTruck from "./components/Refuse-Truck";
import CementTruck from "./components/Cement-Truck";
import DumpTruck from "./components/Dump-Truck";
import CityBus from "./components/City-Bus";
import SchoolBus from "./components/School-Bus";
import CoachBus from "./components/Coach-Bus";
import OrderDetails from "./components/Order-Details";
import Order from "./components/Order";
import ChargerOne from "./components/Charger/charger-one";

function App() {
  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        // User is signed in
        dispatch(
          login({
            email: userAuth.email,
            uid: userAuth.uid,
            displayName: userAuth.displayName,
          })
        );
      } else {
        // User is signed out
        dispatch(logout());
      }
    });
    console.log("HI");
  }, [dispatch]);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/refuse-truck" element={<RefuseTruck />} />
          <Route path="/food-truck" element={<FoodTruck />} />
          <Route path="/cement-truck" element={<CementTruck />} />
          <Route path="/delivery-truck" element={<DeliveryTruck />} />
          <Route path="/dump-truck" element={<DumpTruck />} />
          <Route path="/city-bus" element={<CityBus />} />
          <Route path="/school-bus" element={<SchoolBus />} />
          <Route path="/coach-bus" element={<CoachBus />} />
          <Route path="/details" element={<OrderDetails />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="order/:id" element={<OrderDetails />} />
          <Route path="charger-one" element={<ChargerOne />} />
          <Route
            path="/teslaaccount"
            element={
              <main>{user ? <Account /> : <Navigate to="/login" />}</main>
            }
          />
          <Route
            path="/login"
            element={
              <main>{user ? <Navigate to="/teslaaccount" /> : <Login />}</main>
            }
          />
        </Routes>

        <GlobalStyle />
      </div>
    </Router>
  );
}

export default App;
