import React, { useState, useEffect } from "react";
import { Wrapper, Content, Content1, Expanded } from "./header.style";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import SideNav from "../SideNav";
import CloseIcon from "@mui/icons-material/Close";

const Header = ({ display }) => {
  const [open, setOpen] = useState(false);
  const [truckMenu, setTruckMenu] = useState(false);
  const [busMenu, setBusMenu] = useState(false);
  const [chargeMenu, setChargeMenu] = useState(false);
  const vehicleTypes = ["Truck", "Bus", "Charging Solutions"];
  const Trucks = [
    {
      name: "Delivery Truck",
      learnLink: "/delivery-truck",
      orderLink: "/order/delivery-truck",
    },
    {
      name: "Food Truck",
      learnLink: "/food-truck",
      orderLink: "/order/food-truck",
    },
    {
      name: "Refuse Truck",
      learnLink: "/refuse-truck",
      orderLink: "/order/refuse-truck",
    },
    {
      name: "Cement Truck",
      learnLink: "/cement-truck",
      orderLink: "/order/cement-truck",
    },
    {
      name: "Dump Truck",
      learnLink: "/dump-truck",
      orderLink: "/order/dump-truck",
    },
  ];
  const Buses = [
    // {
    //   name: "School Bus",
    //   learnLink: "/school-bus",
    //   orderLink: "/order/school-bus",
    //   imgLink: "../../images/E-CityBus-6.5/external/exterior2.jpg",
    // },
    {
      name: "City Bus",
      learnLink: "/city-bus",
      orderLink: "/order/city-bus",
      imgLink: "../../images/cityBus-tab.png",
    },
    {
      name: "Coach Bus",
      learnLink: "/coach-bus",
      orderLink: "/order/coach-bus",
      imgLink: "../../images/coachBus-tab.png",
    },
  ];
  const Chargings = [
    {
      name: "Charger 1",
      learnLink: "/charger-one",
      orderLink: "/order/charger-one",
    },
    {
      name: "Charger 2",
      learnLink: "/charger-two",
      orderLink: "/order/charger-two",
    },
  ];

  const handleClick = (vType) => {
    if (vType === "Truck") {
      setTruckMenu((expanded) => !expanded);
      setBusMenu(false);
      setChargeMenu(false);
    } else if (vType === "Bus") {
      setBusMenu((expanded) => !expanded);
      setTruckMenu(false);
      setChargeMenu(false);
    } else {
      setChargeMenu((expanded) => !expanded);
      setBusMenu(false);
      setTruckMenu(false);
    }
  };

  return (
    <>
      <Wrapper>
        <div className="logo">
          <Link to="/">
            <img src="/images/BVX-2.png" className="bvx-logo" alt="logo" />
          </Link>
        </div>
        <Content>
          <div>
            {vehicleTypes.map((type) => {
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href="#"
                  onMouseEnter={() => handleClick(type)}
                  className="vehicle-text"
                  key={type}
                >
                  {type}
                </a>
              );
            })}
          </div>
        </Content>

        <Content1 display={display}>
          <a href="#" onClick={() => setOpen(true)}>
            Menu
          </a>
        </Content1>
        {open && (
          <div className="top">
            <div className="close" onClick={() => setOpen(false)}>
              <CloseIcon />
            </div>
          </div>
        )}
        <SideNav show={open} />

        {/* {truckMenu && (
          <motion.div
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ duration: 1 }}
            className="box-container"
            onMouseLeave={() => handleClick("Truck")}
          >
            <div className="vehicle-box">
              {Trucks.map((truck) => {
                return (
                  <motion.div
                    initial={{ y: -50 }}
                    animate={{ y: 0 }}
                    transition={{ duration: 2 }}
                    key={truck.name}
                    className="vehicle"
                  >
                    <img
                      src="../../../images/food-truck-logo.png"
                      alt="akash"
                      className="vehicle-image"
                    />
                    <h1 className="vehicle-name">{truck.name}</h1>
                    <div className="learn-order">
                      <Link to={truck.learnLink} className="learn">
                        Learn
                      </Link>
                      <Link to={truck.orderLink} className="learn">
                        Order
                      </Link>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
        )} */}
        {busMenu && (
          <motion.div
            className="box-container"
            onMouseLeave={() => handleClick("Bus")}
          >
            <div className="vehicle-box">
              {Buses.map((bus) => {
                return (
                  <motion.div
                    initial={{ y: -100 }}
                    animate={{ y: 0 }}
                    transition={{ duration: 1 }}
                    key={bus.name}
                    className="vehicle transition-div show"
                  >
                    <img
                      src={bus.imgLink}
                      alt="akash"
                      className="vehicle-image"
                    />
                    <h1 className="vehicle-name">{bus.name}</h1>
                    <div className="learn-order">
                      <Link to={bus.learnLink} className="learn">
                        Learn
                      </Link>
                      <Link to={bus.orderLink} className="learn">
                        Order
                      </Link>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
        )}
        {/* {chargeMenu && (
          <motion.div
            className="box-container"
            onMouseLeave={() => handleClick("Charging Solutions")}
          >
            <div className="vehicle-box">
              {Chargings.map((charging) => {
                return (
                  <motion.div
                    initial={{ y: -100 }}
                    animate={{ y: 0 }}
                    transition={{ duration: 1 }}
                    key={charging.name}
                    className="vehicle"
                  >
                    <img
                      src="../../../images/food-truck-logo.png"
                      alt="akash"
                      className="vehicle-image"
                    />
                    <h1 className="vehicle-name">{charging.name}</h1>
                    <div className="learn-order">
                      <Link to={charging.learnLink} className="learn">
                        Learn
                      </Link>
                      <Link to={charging.orderLink} className="learn">
                        Order
                      </Link>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
        )} */}
      </Wrapper>
    </>
  );
};

export default Header;
