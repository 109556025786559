import React, { useEffect } from "react";
import { Wrapper, Outer } from "./details.style";
import { useState } from "react";
import { NumberCounter } from "../NumberCounter";
import ImageCarousel from "../ImageCarousel";
import { imageDetails } from "../imageDetails";
import { motion } from "framer-motion";
import { useMyState } from "../MyStateContext";
import { useParams } from "react-router-dom";
import vehicleDetails from "../vehicleDetails";
import Header from "../Header";
import CardCarousel from "../CardCarousel";
import { Box, Input, Slider } from "@mui/material";
import UserForm from "../UserForm";
import OrderHeader from "../OrderHeader";

const OrderDetails = () => {
  document.title = "order | BVX";
  const imgDetails = imageDetails;
  const tabStyle = {
    background: "white",
  };
  const cardStyle = {
    filter: "blur(7px)",
    background: "#C4C5C6",
  };

  // filtering for specific vehicle
  const { id } = useParams();
  const vType = id.split("-")[1];
  let vDetails = vehicleDetails.filter((vehicle) => vehicle.type === id);

  const [tab, setTab] = useState(1);
  const [isOpen, setIsOpen] = useState({
    val: false,
    feature: "",
  });

  const { state, dispatch } = useMyState();
  const {
    vId,
    type,
    fullDetailsArray,
    productDetail,
    model,
    chasis,
    body,
    extColor,
    intColor,
    range,
    minRange,
    interiorSelected,
  } = state;

  useEffect(() => {
    dispatch({ type: "FIRST_RENDER", payload: { vDetails, vType } });
    console.log(range, "range");
  }, []);

  const selectTab = (tabNum) => {
    return setTab(tabNum);
  };
  const selectProduct = (product) => {
    if (product.id === vId) {
      return;
    }
    dispatch({ type: "SELECT_PRODUCT", payload: product });
  };
  const chasisHandler = (prod) => {
    dispatch({ type: "SELECT_CHASIS", payload: prod });
  };
  const bodyHandler = (prod) => {
    dispatch({ type: "SELECT_BODY", payload: prod });
  };
  const exteriorColorHandler = (prod) => {
    dispatch({ type: "SELECT_EXTPAINT", payload: prod });
  };
  const interiorColorHandler = (prod) => {
    dispatch({ type: "SELECT_INTPAINT", payload: prod });
  };

  // for custom color

  const rangeHandler = (prod) => {
    dispatch({ type: "SELECT_RANGE", payload: prod });
  };

  const handleChange = (ev) => {
    dispatch({ type: "SELECT_RANGE", payload: ev.target.value });
  };

  return (
    <Outer>
      {isOpen.val && (
        <CardCarousel
          feature={isOpen.feature}
          fullDetails={fullDetailsArray}
          productDetail={productDetail}
          type={id}
          closeDialog={() => setIsOpen({ val: false })}
        />
      )}
      {/* <div className="order-header">
        <OrderHeader />
      </div> */}
      <Header display="none" />

      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        style={isOpen.val ? cardStyle : ""}
        className="flex"
      >
        <div className="Seventy">
          <ImageCarousel />
        </div>
        <div className="Thirty">
          <h2 className="mt-40 v-name">{productDetail.name}</h2>
          <p className="mt-10 primary-gray v-delivery">
            Est. Delivery: Mar - Apr 2024
          </p>
          <div className="mt-10">
            <div className="tab-contain">
              <div
                className="purchase"
                onClick={() => selectTab(1)}
                style={tab === 1 ? tabStyle : null}
              >
                Purchase Price
              </div>
              <div
                className="savings"
                onClick={() => selectTab(2)}
                style={tab === 2 ? tabStyle : null}
              >
                Probable Savings*
              </div>
            </div>
            <div className="detail-contain">
              <div className="specification">
                <h2>
                  <NumberCounter
                    initialValue={0}
                    targetNumber={productDetail.speed}
                    duration={4000}
                    vid={vId}
                  />
                  <span>kmph</span>
                </h2>
                <p className="spec">Max Speed</p>
              </div>
              <div className="specification">
                <h2>
                  <NumberCounter
                    initialValue={0}
                    targetNumber={productDetail.range}
                    duration={4000}
                    vid={vId}
                  />
                  <span>km</span>
                </h2>
                <p className="spec">Max Range</p>
              </div>
              <div className="specification">
                <h2>
                  <NumberCounter
                    initialValue={0}
                    targetNumber={productDetail?.minRange}
                    duration={4000}
                    vid={vId}
                  />
                  <span>km</span>
                </h2>
                <p className="spec">Min Range</p>
              </div>
            </div>

            <div>
              {fullDetailsArray &&
                fullDetailsArray.map((product, ii) => {
                  return (
                    <div key={ii}>
                      <div
                        className="body-contain"
                        onClick={() => selectProduct(product)}
                        style={
                          product.model === model
                            ? { border: "3px solid #007bff" }
                            : {}
                        }
                      >
                        <div className="model">{product.model}</div>
                        <div className="model">
                          {tab === 1
                            ? product.price
                            : tab === 2
                            ? product.savings
                            : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div className="feature-button" style={{ marginTop: "20px" }}>
                <button
                  onClick={() => setIsOpen({ val: true, feature: "model" })}
                >
                  Feature Details
                </button>
              </div>
            </div>
            {type === "truck" && (
              <div>
                {/* Chasis  */}
                <h2 className="spec-text mt-10">Chasis</h2>
                <div className="chasis">
                  {productDetail.variant.chasis &&
                    productDetail.variant.chasis.map((prod, i) => {
                      return (
                        <div key={i} onClick={() => chasisHandler(prod)}>
                          <div
                            className="chasis-type"
                            style={
                              prod.type === chasis
                                ? { border: "3px solid #007bff" }
                                : {}
                            }
                          >
                            {prod.type}
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/* Body  */}
                <h2 className="spec-text mt-10">Body</h2>
                <div className="chasis">
                  {productDetail.variant.body &&
                    productDetail.variant.body.map((prod, i) => {
                      return (
                        <div key={i} onClick={() => bodyHandler(prod)}>
                          <div
                            className="chasis-type"
                            style={
                              prod.type === body
                                ? { border: "3px solid #007bff" }
                                : {}
                            }
                          >
                            {prod.type}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {/* Range */}
            <h2 className="spec-text mt-20">Range</h2>
            <div className="km-slider">
              <Box
                sx={{
                  width: 238,
                  "& .MuiSlider-thumb": {
                    color: "#808080",
                  },
                  "& .MuiSlider-track": {
                    color: "#808080",
                  },
                  "& .MuiSlider-rail": {
                    color: "#808080",
                  },
                  "& .MuiSlider-active": {
                    color: "#808080",
                  },
                }}
              >
                <Slider
                  aria-label="Range"
                  defaultValue={30}
                  onChange={handleChange}
                  valueLabelDisplay="auto"
                  marks
                  step={10}
                  min={minRange}
                  max={productDetail.range}
                />
              </Box>
              <h4 style={{ marginLeft: "20px" }}>{range}km</h4>
            </div>

            {/* Exterior Paint */}
            <h2 className="spec-text mt-20">Exterior Paint</h2>
            <div className="chasis">
              {productDetail.variant.extColor &&
                productDetail.variant.extColor.map((prod, i) => {
                  return (
                    <div key={i} onClick={() => exteriorColorHandler(prod)}>
                      <div
                        className="chasis-type"
                        style={
                          prod.type === extColor
                            ? { border: "3px solid #007bff" }
                            : {}
                        }
                      >
                        {prod.type}
                      </div>
                    </div>
                  );
                })}
            </div>
            {/* Interior color  */}
            <h2 className="spec-text mt-20">Interior Color</h2>
            <div className="chasis">
              {productDetail.variant.intColor &&
                productDetail.variant.intColor.map((prod, i) => {
                  return (
                    <div key={i}>
                      <div
                        onClick={() => interiorColorHandler(prod)}
                        className="chasis-type"
                        style={
                          intColor === prod.type && interiorSelected
                            ? { border: "3px solid #007bff" }
                            : {}
                        }
                      >
                        {prod.type}
                      </div>
                    </div>
                  );
                })}
            </div>
            <h2 className="spec-text mt-20">Customize vehicle</h2>
          </div>
          <div style={{ margin: "20px 0" }}>
            <UserForm />
          </div>
        </div>
      </motion.div>
    </Outer>
  );
};

export default OrderDetails;
