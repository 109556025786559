import React from "react";
import { Wrapper, Content } from "./home.style";

import Section from "./Section";
import Header from "./Header";
import Footer from "./Footer";
import OrderDetails from "./Order-Details";

function Home() {
  document.title = "Electric Bus, Truck & Charging | BVX";
  return (
    <>
      <Header />
      <Wrapper>
        <Content>
          <Section
            title="City Bus"
            desc="Order Online for touchless Delivery"
            // link="Touchless Delivery"
            leftbtn="BUILD"
            rightbtn="EXISTING INVENTORY"
            backgroundImg="cityBus.png"
            arrow="true"
            orderLink="order/city-bus"
          />
        </Content>
        <Content>
          <Section
            title="Coach Bus"
            desc="Order Online for touchless Delivery"
            // link="Touchless Delivery"
            leftbtn="BUILD"
            rightbtn="EXISTING INVENTORY"
            backgroundImg="coachBus.png"
            orderLink="order/coach-bus"
          />
        </Content>
        {/* <Content>
          <Section
            title="Dump Truck"
            desc="Order Online for touchless Delivery"
            // link="Touchless Delivery"
            leftbtn="Build"
            rightbtn="EXISTING INVENTORY"
            backgroundImg="model-s.jpg"
          />
        </Content>
        <Content>
          <Section
            title="Food Truck"
            desc="Order Online for touchless Delivery"
            // link="Touchless Delivery"
            leftbtn="Build"
            rightbtn="EXISTING INVENTORY"
            backgroundImg="model-x.jpg"
          />
        </Content>
        <Content>
          <Section
            title="Refuse Truck"
            desc="Lowest Cost Solar Panels in America"
            leftbtn="Build"
            rightbtn="LEARN MORE"
            backgroundImg="solar-panel.jpg"
          />
        </Content>
        <Content>
          <Section
            title="Solar Roof"
            desc="Produce Clean Energy From Your Roof"
            leftbtn="Build"
            rightbtn="LEARN MORE"
            backgroundImg="solar-roof.jpg"
          />
        </Content> */}
        {/* <Content>
          <Section
            title="Accessories"
            leftbtn="SHOP NOW"
            backgroundImg="accessories.jpg"
          />
          <Footer />
        </Content> */}
      </Wrapper>
      {/* <OrderDetails /> */}
    </>
  );
}

export default Home;
