import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  z-index: 1;
  .close {
    color: black;
    padding: 5px;
    padding-bottom: 2px;
    border-radius: 50%;
    position: fixed;
    top: 15px;
    right: 20px;
    z-index: 5;
    transition: all 300ms ease-in;
    cursor: pointer;
    :hover {
      background-color: rgba(90, 90, 90, 0.1);
    }
  }
  .top {
    position: fixed;
    top: 0;
    right: 10px;
    z-index: 10;
    height: 75px;
    width: 270px;
    background-color: white;
    -webkit-animation: cssAnimation 400ms forwards;
    animation: cssAnimation 400ms forwards;
  }
  .vehicle-text {
    z-index: 1;
    position: relative;
    cursor: pointer;
  }
  .vehicle-text:hover {
    padding: 7px 16px;
    font-size: 0.92rem;
    letter-spacing: 0.6px;
    text-decoration: none;
    font-weight: 500;
    border-radius: 10px;
    transition: background-color 200ms ease-in;
    cursor: pointer;
    :hover {
      background-color: rgba(90, 90, 90, 0.1);
    }
  }
  .box-container {
    background: white;
    width: 100vw;
    height: 55svh;
    position: absolute;
    top: 0;
  }
  .vehicle-box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    height: 88%;
    width: 100vw;
    margin-top: 7%;
  }
  .vehicle {
    display: flex;
    justify: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .learn-order {
    display: flex;
    justify-content: space-between;
    width: 40%;
  }
  .vehicle-name {
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 1.1rem;
    color: var(--teslaColor);
  }
  .vehicle-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 50%;
    width: 70%;
  }
  .learn {
    font-size: 0.8rem;
  }
  .transition-div {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .transition-div.show {
    opacity: 1;
  }
  @keyframes cssAnimation {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 1150px) {
    .close {
      background-color: lightgrey;
    }
  }

  .logo {
    position: absolute;
    left: 28px;
    cursor: pointer;
  }
  .bvx-logo{
    height: 4%; 
    width: 8%
  }
  @media screen and (max-width: 600px){
    .bvx-logo{
      height: 10%;
      width: 20%
    }
  }
  .bvx-logo{
      height: "4%",
      width: "8%",
  }
  img {
    height: 16px;
    width: 120px;
  }

  .box-contain {
    width: 100vw;
    position: fixed;
  }
  .mega-box {
    position: absolute;
    left: 0;
    width: 100vw;
    top: 20px;
  }
  .content {
    background-color: white;
    border: 10px;
    padding: 25px 40px;
    display: flex;
    justify-content: space-between;
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .main {
    text-align: center;
  }
  .text {
    font-size: 12px;
    margin: 5px;
    color: black;
    cursor: pointer;
  }
  .category {
    font-size: 14px;
    margin: 10px;
    color: black;
    cursor: pointer;
  }
  .bus {
    color: black;
    font-size: 18px;
  }

  @media screen and (max-width: 1150px) {
    .logo {
      left: 30px;
    }
  }
  @media screen and (max-width: 400px) {
    .logo {
      left: 10px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: nowrap;

  a {
    padding: 7px 16px;
    font-size: 0.92rem;
    letter-spacing: 0.6px;
    text-decoration: none;
    font-weight: 500;
    border-radius: 10px;
    transition: background-color 200ms ease-in;
    cursor: pointer;
    :hover {
      background-color: rgba(90, 90, 90, 0.1);
    }
  }

  @media screen and (max-width: 1150px) {
    display: none;
  }
`;

export const Content1 = styled.div`
  display: ${(props) => `${props.display}`};
  flex-wrap: nowrap;
  position: fixed;
  right: 30px;

  a {
    padding: 7px 16px;
    font-size: 0.92rem;
    letter-spacing: 0.6px;
    text-decoration: none;
    font-weight: 500;
    border-radius: 10px;
    transition: background-color 200ms ease-in;
    cursor: pointer;
    :hover {
      background-color: rgba(90, 90, 90, 0.1);
    }
  }

  @media screen and (max-width: 1150px) {
    .none {
      display: none;
    }
    a {
      background-color: rgba(90, 90, 90, 0.08);
    }
    right: 20px;
  }
  @media screen and (max-width: 400px) {
    right: 15px;
  }
  @media screen and (min-width: 1150px) {
    a {
      display: none;
    }
  }
`;

export const Expanded = styled.div`
  width: 100vw;
  position: fixed;

  .mega-box {
    position: absolute;
    left: 0;
    width: 100vw;
    top: 20px;
  }
  .content {
    background-color: white;
    border: 10px;
    padding: 25px 40px;
    display: flex;
    justify-content: space-between;
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .main {
    text-align: center;
  }
  .text {
    font-size: 12px;
    margin: 5px;
    color: black;
    cursor: pointer;
  }
  .category {
    font-size: 14px;
    margin: 10px;
    color: black;
    cursor: pointer;
  }
  .bus {
    color: black;
    font-size: 18px;
  }
`;
