import React from "react";
import { Wrapper, ContentTop, ContentMid, Content } from "./section.style";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Section({
  title,
  desc,
  backgroundImg,
  link,
  leftbtn,
  rightbtn,
  arrow,
  range,
  speed,
  hp,
  top,
  orderLink,
  buildLink,
}) {
  const navigate = useNavigate();
  const buildHandle = (bLink) => {
    navigate(bLink);
  };
  return (
    <Wrapper bg={backgroundImg}>
      <ContentTop>
        <h1>{title}</h1>
        <p>
          {desc} <a href="#">{link}</a>
        </p>
      </ContentTop>

      <div>
        <ContentMid className={arrow ? "" : "buttons"}>
          {leftbtn && (
            <div className="left">
              <button onClick={() => buildHandle(orderLink)}>{leftbtn}</button>
            </div>
          )}
          {rightbtn && (
            <div className="right">
              <button>{rightbtn}</button>
            </div>
          )}
        </ContentMid>
        {range && (
          <Content>
            <div className="Info-bar">
              <div className="Specs">
                <h2>{range} km</h2>
                <p>Range (EPA est.)</p>
              </div>
              <div className="Specs">
                <h2>{speed}s</h2>
                <p>0-60 mph*</p>
              </div>
              <div className="Specs">
                <h2>{top} kmph</h2>
                <p>Top Speed</p>
              </div>
              {hp && (
                <div className="Specs hp">
                  <h2>{hp} hp</h2>
                  <p>Peak Power</p>
                </div>
              )}
              <div className="order_btn">
                <button onClick={() => buildHandle(buildLink)}> BUILD</button>
              </div>
            </div>
          </Content>
        )}
        {arrow && (
          <div className="arrow">
            <img src="/images/down-arrow.svg" alt="arrow" />
          </div>
        )}
      </div>
    </Wrapper>
  );
}

export default Section;
