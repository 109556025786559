import styled from "styled-components";

export const Wrapper = styled.div`
  border: 1px solid;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    width: 400px;
    border: 1px solid green;
    padding: 20px;
    border-radius: 10px;
  }
  label {
    font-weight: bold;
    margin-right: 3px;
  }
  .radio-option {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    border: 1px solid red;
    padding: 10px;
    border-radius: 7px;
  }
  .button-container {
    text-align: center;
  }
  button {
    padding: 5px 15px;
  }
  .yes-radio {
    margin-right: 10px;
  }
`;
