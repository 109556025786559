export const imageDetails = [
  // city bus
  {
    model: "E-BUS 6.5 METERS",
    exteriorImage: [
      { url: "../../../images/E-CityBus-6.5/external/exterior1.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior2.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior3.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior4.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior5.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior6.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior7.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior8.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior9.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior10.jpg" },
    ],
    interiorImage: [
      { url: "../../../images/E-CityBus-6.5/internal/interior1.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior2.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior3.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior4.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior5.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior6.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior7.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior8.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior9.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior10.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior11.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior12.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior13.jpg" },
    ],
  },
  {
    model: "E-BUS 8.5 METERS",
    exteriorImage: [
      { url: "../../../images/E-CityBus-6.5/external/exterior1.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior2.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior3.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior4.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior5.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior6.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior7.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior8.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior9.jpg" },
      { url: "../../../images/E-CityBus-6.5/external/exterior10.jpg" },
    ],
    interiorImage: [
      { url: "../../../images/E-CityBus-6.5/internal/interior1.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior2.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior3.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior4.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior5.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior6.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior7.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior8.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior9.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior10.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior11.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior12.jpg" },
      { url: "../../../images/E-CityBus-6.5/internal/interior13.jpg" },
    ],
  },
  {
    model: "E-BUS 10.5 METERS",
    exteriorImage: [
      { url: "../../../images/E-CityBus-10.5/external/exterior1.jpg" },
      { url: "../../../images/E-CityBus-10.5/external/exterior2.jpg" },
      { url: "../../../images/E-CityBus-10.5/external/exterior3.jpg" },
      { url: "../../../images/E-CityBus-10.5/external/exterior4.jpg" },
      { url: "../../../images/E-CityBus-10.5/external/exterior5.jpg" },
      { url: "../../../images/E-CityBus-10.5/external/exterior6.jpg" },
      { url: "../../../images/E-CityBus-10.5/external/exterior7.jpg" },
      { url: "../../../images/E-CityBus-10.5/external/exterior8.jpg" },
      { url: "../../../images/E-CityBus-10.5/external/exterior9.jpg" },
    ],
    interiorImage: [
      { url: "../../../images/E-CityBus-10.5/internal/interior1.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior2.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior3.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior4.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior5.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior6.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior7.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior8.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior9.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior10.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior11.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior12.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior13.jpg" },
    ],
  },
  // coach bus
  {
    model: "8.2m coach",
    exteriorImage: [
      { url: "../../../images/E-CoachBus-11/external/exterior1.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior2.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior3.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior4.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior5.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior6.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior7.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior8.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior9.jpg" },
    ],
    interiorImage: [
      { url: "../../../images/E-CityBus-10.5/internal/interior1.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior2.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior3.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior4.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior5.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior6.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior7.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior8.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior9.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior10.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior11.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior12.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior13.jpg" },
    ],
  },
  {
    model: "11m coach",
    exteriorImage: [
      { url: "../../../images/E-CoachBus-11/external/exterior1.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior2.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior3.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior4.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior5.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior6.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior7.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior8.jpg" },
      { url: "../../../images/E-CoachBus-11/external/exterior9.jpg" },
    ],
    interiorImage: [
      { url: "../../../images/E-CityBus-10.5/internal/interior1.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior2.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior3.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior4.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior5.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior6.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior7.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior8.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior9.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior10.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior11.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior12.jpg" },
      { url: "../../../images/E-CityBus-10.5/internal/interior13.jpg" },
    ],
  },
];
