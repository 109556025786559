import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { Wrapper, Content } from "./spec.style";

const Specs = () => {
  useEffect(() => {
    AOS.init({ duration: 1400 });
    AOS.refresh();
  }, []);

  return (
    <Wrapper>
      <Content>
        <div className="row">
          <div className="col" data-aos="fade-right">
            <img src="/images/interior3.jpg" alt="game" />
          </div>
          <div className="col text" data-aos="fade-left">
            <h3> Introducing "E-Connect Hub" </h3>
            <p>
              {" "}
              Our innovative onboard system offers passengers a range of
              features to enhance their travel experience, without the need for
              gaming..{" "}
            </p>
          </div>
        </div>
        <div className="row col-reverse">
          <div className="col text1" data-aos="fade-right">
            <h3> Stay Connected </h3>
            <p>
              {" "}
              Multi-device Bluetooth, wireless and USB-C charging for every
              passenger, with enough power to fast-charge your tablets and
              laptop.{" "}
            </p>
          </div>
          <div className="col" data-aos="fade-left">
            <img src="/images/interior7.jpg" alt="connected" />
          </div>
        </div>
        <div className="row">
          <div className="col" data-aos="fade-right">
            <img src="/images/interior9.jpg" alt="connected" />
          </div>
          <div className="col text" data-aos="fade-left">
            <h3> Introducing "E-Relax Lounge"</h3>
            <p>
              {" "}
              The ultimate relaxation experience on your e-bus journey! Our
              innovative onboard feature offers passengers a serene and
              comfortable environment to unwind and destress during their
              travels.{" "}
            </p>
          </div>
        </div>
      </Content>
    </Wrapper>
  );
};

export default Specs;
