import React from "react";

import { Wrapper } from "./city.style";

import Section from "../Section";
import Interior from "../Interior_Specs";
import Specs from "../Gen_Spec";
import Header from "../Header";
import Footer from "../Footer";
import { useMyState } from "../MyStateContext";
import CardCarousel from "../CardCarousel";

const CityBus = () => {
  document.title = "City Bus | BVX";
  const { state, dispatch } = useMyState();
  return (
    <Wrapper>
      <Header display="flex" />
      {console.log(state, "State")}
      <Section
        title="City Bus"
        desc="10.5m bus"
        backgroundImg="cityBus.png"
        arrow="true"
        range="160"
        speed="9"
        top="69"
        // hp="1,020"
        buildLink="/order/city-bus"
      />
      <Interior />
      <Specs />
      <Footer bottom="bottom" />
    </Wrapper>
  );
};

export default CityBus;
