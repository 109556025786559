const vehicleDetails = [
  {
    id: 1,
    type: "food-truck",
    model: "Food Truck V7",
    name: "Food Truck",
    color: "red",
    price: "$ 30,00000",
    savings: "$ 2,00000",
    range: 140,
    minRange: 50,
    speed: 50,
    acc: 3.1,
    img: "Food truck v7 image",
    variant: {
      chasis: [
        { id: 1, type: "6*4" },
        { id: 2, type: "8*6" },
      ],
      body: [
        { id: 1, type: "High spec" },
        { id: 2, type: "Mid spec" },
        { id: 3, type: "Low spec" },
      ],
      extColor: [
        { id: 1, type: "Red" },
        { id: 2, type: "Green" },
        { id: 3, type: "Blue" },
        { id: 4, type: "custom" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "Green" },
        { id: 3, type: "custom" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
    },
  },
  {
    id: 2,
    type: "food-truck",
    model: "Food Truck V2",
    name: "Food Truck",
    color: "red",
    price: "$ 25,00000",
    savings: "$ 5,00000",
    range: 210,
    speed: 70,
    acc: 4.5,
    img: "Food truck v2 image",
    variant: {
      chasis: [
        { id: 1, type: "6*4" },
        { id: 2, type: "8*6" },
      ],
      body: [
        { id: 1, type: "High spec" },
        { id: 2, type: "Mid spec" },
        { id: 3, type: "Low spec" },
      ],
      extColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "Green" },
        { id: 3, type: "Purple" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "Green" },
        { id: 3, type: "custom" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
    },
  },
  {
    id: 3,
    type: "food-truck",
    model: "Food Truck V3",
    name: "Food Truck",
    color: "red",
    price: "$ 27,00000",
    savings: "$ 3,00000",
    range: 305,
    speed: 80,
    acc: 6.1,
    img: "Food truck v3 image",
    variant: {
      chasis: [
        { id: 1, type: "6*4" },
        { id: 2, type: "8*6" },
      ],
      body: [
        { id: 1, type: "High spec" },
        { id: 2, type: "Mid spec" },
        { id: 3, type: "Low spec" },
      ],
      extColor: [
        { id: 1, type: "Red" },
        { id: 2, type: "Voilet" },
        { id: 3, type: "Blue" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "Green" },
        { id: 3, type: "custom" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
    },
  },
  // delivery truck (3)
  {
    id: 4,
    type: "delivery-truck",
    model: "Delivery Truck V1",
    name: "Delivery Truck",
    color: "red",
    price: "$ 27,00000",
    savings: "$ 3,00000",
    range: 305,
    speed: 80,
    acc: 6.1,
    img: "Delivery truck v1 image",
    variant: {
      chasis: [
        { id: 1, type: "6*4" },
        { id: 2, type: "8*6" },
      ],
      body: [
        { id: 1, type: "High spec" },
        { id: 2, type: "Mid spec" },
        { id: 3, type: "Low spec" },
      ],
      extColor: [
        { id: 1, type: "White" },
        { id: 2, type: "Green" },
        { id: 3, type: "Blue" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "Green" },
        { id: 3, type: "custom" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
    },
  },
  {
    id: 5,
    type: "delivery-truck",
    model: "Delivery Truck V2",
    name: "Delivery Truck",
    color: "Green",
    price: "$ 32,00000",
    savings: "$ 5,00000",
    range: 402,
    speed: 79,
    acc: 5.6,
    img: "Delivery truck v2 image",
    variant: {
      chasis: [
        { id: 1, type: "6*4" },
        { id: 2, type: "8*6" },
      ],
      body: [
        { id: 1, type: "High spec" },
        { id: 2, type: "Mid spec" },
        { id: 3, type: "Low spec" },
      ],
      extColor: [
        { id: 1, type: "Red" },
        { id: 2, type: "Orange" },
        { id: 3, type: "Purple" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "Green" },
        { id: 3, type: "custom" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
    },
  },
  {
    id: 6,
    type: "delivery-truck",
    model: "Delivery Truck V3",
    name: "Delivery Truck",
    color: "Voilet",
    price: "$ 45,00000",
    savings: "$ 32,00000",
    range: 105,
    speed: 50,
    acc: 3.7,
    img: "Delivery truck v3 image",
    variant: {
      chasis: [
        { id: 1, type: "6*4" },
        { id: 2, type: "8*6" },
      ],
      body: [
        { id: 1, type: "High spec" },
        { id: 2, type: "Mid spec" },
        { id: 3, type: "Low spec" },
      ],
      extColor: [
        { id: 1, type: "Red" },
        { id: 2, type: "Green" },
        { id: 3, type: "Blue" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "Green" },
        { id: 3, type: "custom" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
    },
  },
  // Cement truck (2)
  {
    id: 7,
    type: "cement-truck",
    model: "Cement Truck V1",
    name: "Cement Truck",
    color: "White",
    price: "$ 35,00000",
    savings: "$ 8,00000",
    range: 80,
    speed: 90,
    acc: 5.7,
    img: "Cement truck v1 image",
    variant: {
      chasis: [
        { id: 1, type: "6*4" },
        { id: 2, type: "8*6" },
      ],
      body: [
        { id: 1, type: "High spec" },
        { id: 2, type: "Mid spec" },
        { id: 3, type: "Low spec" },
      ],
      extColor: [
        { id: 1, type: "Cyant" },
        { id: 2, type: "Green" },
        { id: 3, type: "Orange" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "Green" },
        { id: 3, type: "custom" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
    },
  },
  {
    id: 8,
    type: "cement-truck",
    model: "Cement Truck V2",
    name: "Cement Truck",
    color: "Blue",
    price: "$ 42,00000",
    savings: "$ 21,00000",
    range: 40,
    speed: 32,
    acc: 8.7,
    img: "Cement truck v2 image",
    variant: {
      chasis: [
        { id: 1, type: "6*4" },
        { id: 2, type: "8*6" },
      ],
      body: [
        { id: 1, type: "High spec" },
        { id: 2, type: "Mid spec" },
        { id: 3, type: "Low spec" },
      ],
      extColor: [
        { id: 1, type: "Orange" },
        { id: 2, type: "Green" },
        { id: 3, type: "White" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "Green" },
        { id: 3, type: "custom" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
    },
  },
  // Refuse truck (1)
  {
    id: 9,
    type: "refuse-truck",
    model: "Refuse Truck V1",
    name: "Refuse Truck",
    color: "Blue",
    price: "$ 42,00000",
    savings: "$ 21,00000",
    range: 40,
    speed: 32,
    acc: 8.7,
    img: "Refuse truck v2 image",
    variant: {
      chasis: [
        { id: 1, type: "6*4" },
        { id: 2, type: "8*6" },
      ],
      body: [
        { id: 1, type: "High spec" },
        { id: 2, type: "Mid spec" },
        { id: 3, type: "Low spec" },
      ],
      extColor: [
        { id: 1, type: "Orange" },
        { id: 2, type: "Green" },
        { id: 3, type: "White" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "Green" },
        { id: 3, type: "custom" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
    },
  },
  // Dump truck (1)
  {
    id: 10,
    type: "dump-truck",
    model: "Dump Truck V2",
    name: "Dump Truck",
    color: "Blue",
    price: "$ 42,00000",
    savings: "$ 21,00000",
    range: 40,
    speed: 32,
    acc: 8.7,
    img: "Dump truck v2 image",
    variant: {
      chasis: [
        { id: 1, type: "6*4" },
        { id: 2, type: "8*6" },
      ],
      body: [
        { id: 1, type: "High spec" },
        { id: 2, type: "Mid spec" },
        { id: 3, type: "Low spec" },
      ],
      extColor: [
        { id: 1, type: "Orange" },
        { id: 2, type: "Green" },
        { id: 3, type: "White" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "Green" },
        { id: 3, type: "custom" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
    },
  },
  // BUS
  // School Bus (1)
  {
    id: 11,
    type: "school-bus",
    model: "School Bus V1",
    name: "School Bus",
    color: "Blue",
    price: "$ 42,00000",
    savings: "$ 21,00000",
    range: 40,
    speed: 32,
    acc: 8.7,
    img: "School Bus v1 image",
    variant: {
      length: [
        { id: 1, type: "Short" },
        { id: 2, type: "Long" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
      extColor: [
        { id: 1, type: "Orange" },
        { id: 2, type: "Green" },
        { id: 3, type: "White" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "Green" },
        { id: 3, type: "custom" },
      ],
    },
  },
  //City Bus (3)
  {
    id: 12,
    type: "city-bus",
    model: "E-BUS 6.5 METERS",
    name: "City Bus",
    color: "Blue",
    price: "US$ 147,500",
    savings: "US$ 21,000",
    seats: "10 - 19",
    passengers: "40,44",
    dimension: "6530*2100*2740,2780,2855,2895,2960",
    angle: "10/12",
    speed: 69,
    slope: ">=12",
    ramp: "12%",
    braking:
      "Full load 30km/h, maximum braking distance on flat and dry road surface: <10m",
    minRange: 160,
    range: 350,
    battery:
      "Type:Lithium iron phosphate, with heating function and natural cooling; capacity(kWh):95.25",
    charging: "Single dun DC",
    tyre: "215/75R17.5",
    rim: "17.5-inch steel wheels",
    panoramicSystem: "Optional",
    fatigueSystem: "Optional",
    acc: 8.7,
    batteryCapacity: 90,
    img: "City Bus V1 image",
    variant: {
      length: [
        { id: 1, type: "Short" },
        { id: 2, type: "Long" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
      extColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "White" },
        { id: 3, type: "Custom" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "custom" },
      ],
    },
  },
  {
    id: 13,
    type: "city-bus",
    model: "E-BUS 8.5 METERS",
    name: "City Bus",
    color: "Blue",
    price: "US$ 212,500",
    savings: "US$ 21,000",
    seats: "2+1 layout, 22+1",
    passengers: 66,
    dimension: "8520/2460/3195",
    angle: "10/12",
    speed: 69,
    slope: ">=18(65% load)",
    slopeAbility:
      "When vehicle is loaded at 65% load and SOC >= 30%, the slope shall not be less than 16%",
    braking:
      "Full load 30km/h, maximum braking distance on flat and dry road surface: <=9.5m",
    minRange: 200,
    range: 350,
    battery:
      "Type:Lithium iron phosphate, with heating function and natural cooling; capacity(kWh):186.7",
    charging: "Single dun DC",
    tyreAndRim:
      "22.5*7.5 inch steel wheels; 255/70R 22.5 16 level radial tyre without inner tube; with spare tyre,tyre pressure monitoring - none",
    panoramicSystem: "N/A",
    fatigueSystem: "N/A",
    acc: 8.7,
    batteryCapacity: 187,
    img: "City Bus V1 image",
    variant: {
      length: [
        { id: 1, type: "Short" },
        { id: 2, type: "Long" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
      extColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "White" },
        { id: 3, type: "Custom" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "custom" },
      ],
    },
  },
  {
    id: 14,
    type: "city-bus",
    model: "E-BUS 10.5 METERS",
    name: "City Bus",
    color: "Blue",
    price: "US$ 260,000",
    savings: "US$ 21,000",
    seats: "2+2 arrangement, 35+1",
    passengers: "75+1",
    dimension: "10500/2550/3300",
    angle: "7/8",
    speed: 90,
    slope: ">=16(65% load)",
    slopeAbility:
      "When vehicle is loaded at 65% load and SOC >= 30%, the slope shall not be less than 16%",
    braking:
      "Full load 30km/h, maximum braking distance on flat and dry road surface: <=7m",
    minRange: 200,
    range: 280,
    battery:
      "Type:Lithium iron phosphate, liquid cooling with heating function and natural cooling; capacity(kWh):350",
    charging: "Single dun DC",
    tyre: "275/70R 22.5 18 level radial tyre without inner tube; with spare tyre",
    rim: "22.5*8.25-inch aluminium alloy wheels,including spare tyre wheels",
    panoramicSystem: "Yes",
    fatigueSystem: "N/A",
    acc: 8.7,
    batteryCapacity: 255,
    img: "City Bus V1 image",
    variant: {
      length: [
        { id: 1, type: "Short" },
        { id: 2, type: "Long" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
      extColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "White" },
        { id: 3, type: "Custom" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "custom" },
      ],
    },
  },
  // Coach Bus (2)
  {
    id: 15,
    type: "coach-bus",
    model: "8.2m coach",
    name: "Coach Bus",
    color: "Blue",
    price: "US$ 196,250",
    savings: "US$ 21,000",
    seats: "37+1",
    dimension: "8195/2480/3110,3420,3480",
    wheelbase: "3900",
    frontOverhang: "1855",
    rearOverhang: "2440",
    trackWidth: "Front wheel 2060, Rear wheel 1804/1844/1864",
    angle: "11/11",
    speed: 100,
    slope: ">=15(65% load)",
    slopeAbility: "The entire vehicle should reliably stop on a 15% slope",
    braking:
      "Full load 30km/h, maximum braking distance on flat and dry road surface: <=9.5m",
    minRange: 200,
    range: 350,
    battery:
      "Type:Lithium iron phosphate, naturally cooled with heating function; capacity(kWh):210",
    charging: "Single dun DC",
    tyreAndRim: "255 70R 22.5; with spare tyre, 22.5*7.25 inch steel wheels",
    acc: 8.7,
    img: "Coach Bus V1 image",
    variant: {
      length: [
        { id: 1, type: "Short" },
        { id: 2, type: "Long" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
      extColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "White" },
        { id: 3, type: "Custom" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "custom" },
      ],
    },
  },
  {
    id: 16,
    type: "coach-bus",
    model: "11m coach",
    name: "Coach Bus",
    color: "Green",
    price: "US$ 225,000",
    savings: "US$ 12,000",
    seats: "47+1",
    dimension: "10990/2500/3530",
    wheelbase: "5500",
    frontOverhang: "2290",
    rearOverhang: "3200",
    trackWidth: "Front wheel 2060/2076, Rear wheel 1870/1876",
    angle: "10/8",
    speed: 100,
    slope: ">=15(65% load)",
    slopeAbility: "The entire vehicle should reliably stop on a 15% slope",
    braking:
      "Full load 30km/h, maximum braking distance on flat and dry road surface: <=9.5m",
    minRange: 200,
    range: 350,
    battery:
      "Type:Lithium iron phosphate, naturally cooled with heating function; capacity(kWh):255.48",
    charging: "Single dun DC",
    tyreAndRim:
      "11R 22.5 18 level radial tyre without inner tube, 22.5*8.25 inch steel wheels",
    acc: 2,
    img: "Coach Bus V2 image",
    variant: {
      length: [
        { id: 1, type: "Short" },
        { id: 2, type: "Long" },
      ],
      range: [
        { id: 1, type: "Short" },
        { id: 2, type: "Mid" },
        { id: 3, type: "Long" },
      ],
      extColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "White" },
        { id: 3, type: "Custom" },
      ],
      intColor: [
        { id: 1, type: "Black" },
        { id: 2, type: "custom" },
      ],
    },
  },
];

export default vehicleDetails;
