import "./form.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMyState } from "../MyStateContext";
import { CircularProgress } from "@mui/material";

const UserForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    customInput: "",
  });
  const [loading, setLoading] = useState(false);
  const handleChange = (ev) => {
    let name = ev.target.name;
    let value = ev.target.value;
    setFormData({ ...formData, [name]: value });
  };
  const submitForm = async (ev) => {
    ev.preventDefault();
    const { firstName, lastName, email, phone, customInput } = formData;
    console.log(formData);
    console.log(
      type + " " + model + " " + extColor + " " + intColor + " " + range
    );
    setLoading(true);
    const res = await fetch("https://usebasin.com/f/e2f1a884bf9c", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_name: firstName + " " + lastName,
        email_id: email,
        phone_no: phone,
        model: model,
        external_color: extColor,
        internal_color: intColor,
        range: range,
        customize: customInput,
      }),
    });
    setLoading(false);
    if (res) {
      navigate("/");
    }
  };
  const { state } = useMyState();
  const { type, model, extColor, intColor, range } = state;
  return (
    <div className="form-container">
      <h5>
        <i>
          Complete the form below and our team will be in touch with you shortly
        </i>
      </h5>
      <form onSubmit={submitForm}>
        <div className="form-group">
          <label htmlFor="firstName">First name:</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last name:</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone no:</label>
          <input
            type="number"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="customInput">Customization details:</label>
          <textarea
            id="customInput"
            name="customInput"
            value={formData.customInput}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit">
          Submit
        </button>
      </form>
      {loading && (
        <div className="spinner">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default UserForm;
