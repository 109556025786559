import React from "react";
import { Wrapper, Content } from "./sidenav.style";

import { Link } from "react-router-dom";

function SideNav({ show }) {
  return (
    <Wrapper show={show}>
      <Content>
        <Link to="/city-bus">
          <li className="half">City Bus</li>
        </Link>
        <Link to="/coach-bus">
          <li className="half">Coach Bus</li>
        </Link>
        {/* <Link to="/login">
            <li className="half">Account</li>
          </Link> */}
      </Content>
    </Wrapper>
  );
}

export default SideNav;
