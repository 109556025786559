import { useEffect, useReducer } from "react";
import { createContext, useContext } from "react";
import reducer from "./Reducers/vehicleReducer";
import vehicleDetails from "./vehicleDetails";

const MyStateContext = createContext();
const MyStateProvider = ({ children }) => {
  const details = vehicleDetails;
  const initialState = {
    vId: details[0].id,
    type: "truck",
    fullDetailsArray: details,
    productDetail: details[0],
    model: details[0].model,
    chasis: details[0].variant.chasis ? details[0].variant.chasis[0].type : "",
    body: details[0].variant.body ? details[0].variant.body[0].type : "",
    extColor: details[0].variant.extColor[0].type,
    intColor: details[0].variant.intColor[0].type,
    minRange: details[0].minRange,
    range: details[0].range,
    interiorSelected: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <MyStateContext.Provider value={{ state, dispatch }}>
      {children}
    </MyStateContext.Provider>
  );
};

const useMyState = () => {
  return useContext(MyStateContext);
};
export { MyStateProvider, MyStateContext, useMyState };
