import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) =>
    `url('/images/${props.bg}')`}; //url is by default in public section

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  img {
    margin-top: 15px;
    margin-bottom: 10px;
    height: 50px;
    animation: animate infinite 1.8s;
  }
  .arrow {
    display: flex;
    justify-content: center;
  }

  .buttons {
    margin-bottom: 60px;
  }

  @keyframes animate {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(5px);
    }
    60% {
      transform: translateY(3px);
    }
  }
  @media screen and (max-width: 600px) {
    img {
      height: 40px;
    }
  }
`;
export const Outer = styled.div`
@media screen and (min-width: 600px){
.flex{
    display: flex;
  }
  .Seventy {
    width: 60vw;
    position: sticky;
    top: 0;
    bottom:0;
    height: 100vh;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Thirty {
    align-items: center;
    text-align-center;
    display: flex;
    flex-direction: column;
    width: 40vw;
    margin-left: auto;
    overflow: hidden;
  }
}
.km-slider{
     display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}
@media screen and (max-width: 600px){
  .v-name{
    font-size : 20px
  }
  .v-delivery{
    font-size : 13px
  }
  .purchase{
    padding : 5px 10px
  }
  .savings{
    padding : 5px 10px
  }
  h2{
    font-size : 15px
  }
  .model{
    font-size : 12px
  }
   .Seventy {
   margin-top : 80px;
   padding : 0 20px
  }
  .Thirty {
    align-items: center;
    text-align-center;
    overflow: hidden;
    padding : 0 20px
  }
  .user-form{
    margin-top : 30px
  }
  .km-slider{
    width : 300px;
    margin-left : 30px
  }
}
 
  .tab-contain {
    display: flex;
    justify-content: space-between;
    background-color: #D3D3D3;
    padding: 3px 3px 4px 3px;
    border-radius: 2px;
    // align-items: center;
  }
  .purchase {
    font-size: 12px;
    padding: 10px 30px;
    cursor: pointer;
  }
  .savings {
    font-size: 12px;
    padding: 10px 30px;
    cursor: pointer;
  }
  .body-contain{
    display: flex;
    justify-content: space-between;
    border: 1px solid;
    align-items: center;
    margin-top : 10px;
    padding: 15px 25px;
    border-radius: 3px;
    cursor: pointer;
  }
  .mt-10{
    margin-top : 10px;
  }
  .mt-15{
    margin-top : 15px;
  }
  .mt-20{
    margin-top : 20px;
  }
  .mt-40{
    margin-top: 40px;
  }
  .detail-contain{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 30px auto;
  }
  h2{
    font-weight : 500;
    display: flex;
    align-items: baseline;
    letter-spacing: 1px
  }
  .specification{
    text-align: center
  }
  .spec{
    font-size: 12px;
    margin-top: 10px;
    color: #808080;
  }
  span{
    font-size: 12px
  }
  .chasis{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 15px auto;
  }
  .chasis-type{
    border: 1px solid;
    border-radius: 8px;
    padding: 10px 30px;
    cursor: pointer;
    font-size: 12px
  }
  .color-type{
    border-radius: 100%;
    padding: 15px;
    cursor: pointer;
    border: 1px solid
  }
  .spec-text{
    display: flex;
    align-items: center;
    justify-content: center
  }

  .feature-button{
    text-align: center;
  }
  .feature-button>button{
    padding: 10px 28px;
    font-size: 13px;
    color: #808080;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: #DCDCDC;
  }
  .feature-button>button:hover{
    background: #D3D3D3;
  }
  .dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

`;
